import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

function licenseImport(data) {
    //license导入
    return router({
      url: baseUrl+"license/v1/import",
      data: data,
      method: "POST"
    });
}
function licenseGet(data) {
    // 获取license信息
    return router({
        url: baseUrl+"license/v1/get",
        data: data,
        method: "POST",
    });
}
  
  export {
    licenseImport,
    licenseGet
}