<template>
  <div class="license-page">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("license.Welcome")
      }}</router-link>
      >
      <span style="color: #3461ff">{{ $t("license.Licenses") }}</span>
    </div>
    <div class="license-page_content">
      <div class="lotetop">{{ $t("license.license") }}</div>
      <template v-if="!isUnauthorized">
        <div class="unauthorized flex_ flex-row-center flex-column-center">
          <icon-svg name="triangle-tips" class="icon-tips"></icon-svg>
          <div class="unauthorized-text">
            {{ $t("license.notAuthTips") }}
          </div>
          <UploadFile
            :uploadBtnWidth="177"
            :submitBtnWidth="120"
            :placeholder="$t('license.SelectLicenseFile')"
            @handelSubmit="handelSubmit"
          />
        </div>
      </template>

      <template v-else>
        <div
          class="
            license-page_content-tips
            flex_ flex-row-center flex-column-center
          "
        >
          <icon-svg name="bz-tips" class="icon-tips"></icon-svg>
          <span>
            {{ $t("license.AuthorizedTo") }} {{ userData.customerName }},
            {{ $t("license.AuthorizedTips") }}
          </span>
        </div>
        <div class="license-page_content-filter flex_ flex-row-between">
          <div class="left flex_">
            <div class="left_box">
              <span class="label">{{ $t("license.Expiration") }} </span>
              <el-date-picker
                v-model="pageParams.expirationInputValue"
                type="datetime"
                placement="bottom-start"
                disabled
                style="width: 191px"
              >
              </el-date-picker>
            </div>
            <div>
              <span class="label">{{ $t("license.creationTime") }} </span>
              <el-date-picker
                v-model="pageParams.creationInputValue"
                type="datetime"
                placeholder=""
                placement="bottom-start"
                disabled
                style="width: 191px"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="right flex_">
            <UploadFile
              :uploadBtnWidth="230"
              :submitBtnWidth="120"
              :placeholder="$t('license.SelectNewLicenseFile')"
              @handelSubmit="handelSubmit"
            />
          </div>
        </div>
        <div class="license-page_content-table">
          <div class="headers flex_ flex-column-center">
            <span class="flex_ flex-column-center header-label">{{
              $t("license.Function")
            }}</span>
            <span>{{ $t("license.Nubmer") }}</span>
          </div>
          <!-- tablebody -->
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Stores") }}</span
            ><span>{{ licenseInfo.storeNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Users") }}</span
            ><span>{{ licenseInfo.userNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Scenarios") }}</span
            ><span>{{ licenseInfo.scenarioNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Scenarios Fields") }}</span
            ><span>{{ licenseInfo.scenarioFieldNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Templates") }}</span
            ><span>{{ licenseInfo.templateNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Layouts") }}</span
            ><span>{{ licenseInfo.layoutNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Images") }}</span
            ><span>{{ licenseInfo.imageNum }}</span>
          </div>
           <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Enabled Tasks") }}</span
            ><span>{{ licenseInfo.taskNum }}</span>
          </div>
          <div class="tablebody">
            <span class="item-label">{{ this.$t("license.Working Interfaces") }}</span
            ><span>{{ licenseInfo.interfaceNum }}</span>
          </div>
          <!-- <div v-for="(item, index) in list" :key="index" class="tablebody">
            <span class="item-label">{{ item.name }}</span
            ><span>{{ item.value }}</span>
          </div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UploadFile from "./UploadFile";

import { licenseImport, licenseGet } from "@/libs/api/license";
export default {
  components: {
    UploadFile,
  },
  data() {
    return {
      pageParams: {
        expirationInputValue: "",
        creationInputValue: "",
      },
      isUnauthorized: false, // 是否授权
      licenseInfo: {},
      list: [],
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
    };
  },
  computed: {
    // licenseInfo() {
    //   return {
    //     storeNum: this.$t("license.Stores"),
    //     userNum: this.$t("license.Users"),
    //     scenarioNum: this.$t("license.Scenarios"),
    //     scenarioFieldNum: this.$t("license.Scenarios Fields"),
    //     templateNum: this.$t("license.Templates"),
    //     layoutNum: this.$t("license.Layouts"),
    //     imageNum: this.$t("license.Images"),
    //     taskNum: this.$t("license.Enabled Tasks"),
    //     interfaceNum: this.$t("license.Working Interfaces"),
    //     // machineCode: "Mac Address",
    //   };
    // },
  },
  methods: {
    async handelSubmit(param) {
      param = param || {};
      try {
        const { data } = await licenseImport(param.formData);
        if (data.state === true) {
          this.$message.success(this.$t("license.ImportSuccess"));
          this.getLicense();

          //
          window.location.reload();
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getLicense() {
      try {
        const { data } = await licenseGet({});
        if (data.state === true) {
          const dataNew = data.data || {};
          if (dataNew && JSON.stringify(dataNew) !== "{}") {
            this.isUnauthorized = true;
            this.licenseInfo = dataNew
            // this.list = this.handleObjectToArray(dataNew) || [];
          }
          this.pageParams.expirationInputValue = dataNew.expireTime;
          this.pageParams.creationInputValue = dataNew.createTime;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
  },
  created() {
    this.getLicense();
  },
};
</script>

<style scoped lang="scss">
.license-page {
  .flex_ {
    display: flex;
  }
  .flex-column-center {
    align-items: center;
  }
  .flex-row-center {
    justify-content: center;
  }
  .flex-row-between {
    justify-content: space-between;
  }
  .flex-row-around {
    justify-content: space-around;
  }
  &_content {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 25px 30px;

    .lotetop {
      border-bottom: 1px solid #e6e5e5;
      padding-bottom: 25px;
      font-size: 20px;
      color: #333333;
    }

    .unauthorized {
      width: 550px;
      background: #fffdf9;
      border: 1px solid #ffe0a5;
      border-radius: 5px;
      margin: 80px auto;
      padding: 20px;
      flex-direction: column;
      svg {
        font-size: 50px;
        padding-bottom: 10px;
        color: rgba(255, 189, 62, 1);
      }
      &-text {
        font-size: 12px;
        color: #666666;
        margin-bottom: 15px;
      }
    }

    &-tips {
      height: 72px;
      background: #fffdf9;
      border: 1px solid #ffe0a5;
      border-radius: 10px;
      margin-top: 20px;
      svg {
        color: #fca958;
        font-size: 21px;
      }
      .icon-tips {
        margin-right: 9px;
      }
      span {
        font-size: 18px;
        color: #fca958;
      }
    }
    &-filter {
      margin-top: 25px;
      .left {
        .left_box {
          margin-right: 30px;
        }
      }
      .label {
        margin-right: 15px;
      }
    }
    &-table {
      margin-top: 24px;
      .headers {
        color: #28c76f;
        height: 40px;
        background: #f5f5f5;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border: 1px solid #e8e8e8;
        span {
          width: 50%;
          text-align: center;
        }
        .header-label {
          text-align: left;
          padding-left: 60px;

          border-right: 1px solid #e8e8e8;
          height: 100%;
        }
      }
      .tablebody {
        width: 100%;
        // margin-left: 15px;
        display: flex;
        span {
          display: inline-block;
          width: 50%;
          height: 40px;
          line-height: 40px;
          padding-left: 30px;
          border-bottom: 1px solid #e8e8e8;
          border-right: 1px solid #e8e8e8;
          font-size: 14px;
          text-align: center;
        }
        .item-label {
          text-align: left;
          padding-left: 60px;
          border-left: 1px solid #e8e8e8;
        }
      }
    }
  }
}
</style>