<template>
  <div class="upload-box">
    <div :style="{ minWidth: uploadBtnWidth + 'px' }">
      <el-upload
        class="upload-demo"
        action="#"
        accept=""
        :before-upload="beforeUpload"
        :http-request="uploadHttpRequest"
        :show-file-list="false"
      >
        <el-button class="input-box" size="medium" type="primary">
          <span v-if="!filesObj.fileName"> {{ placeholder }} </span>
          <span v-else>
            {{ filesObj.fileName }}
          </span>
        </el-button>
      </el-upload>
    </div>

    <div :style="{ minWidth: submitBtnWidth + 'px' }" style="margin-left: 10px">
      <el-button
        class="submit-btn"
        size="medium"
        type="primary"
        @click="handelSubmit"
      >
        <icon-svg name="upload"></icon-svg>
        <span>{{$t('license.Import')}}</span>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "LicenseUploadFile",
  props: {
    importType: {
      type: String,
      require: true,
      default: "excel",
    },
    acceptFileType: {
      type: String,
      require: true,
      default: "doc",
    },

    placeholder: {
      type: String,
      require: true,
      default: "",
    },
    uploadBtnWidth: {
      type: Number,
      require: true,
      default: 200,
    },
    submitBtnWidth: {
      type: Number,
      require: true,
      default: 120,
    },
  },

  data() {
    return {
      importType_: "excel",
      filesObj: {
        formData: {},
        fileName: "",
      },
    };
  },
  methods: {
    beforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      if (lastName.toLowerCase() !== ".license") {
        this.$message.error(this.$t('license.fileTypeTips'));
        return;
      }

      if (file) {
        this.filesObj.fileName = file.name;
      }
      // 文件大小限制为10M
      // const fileLimit = file.size / 1024 / 1024 < 10;
      // if (!fileLimit) {
      //   this.$message.error("上传文件大小不超过10M！");
      // }
    },
    uploadHttpRequest(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      this.filesObj.formData = formData;
    },
    handelSubmit() {
      if (!this.filesObj.fileName) {
        this.$message.warning(this.$t('license.importTips'));
        return;
      }
      this.$emit("handelSubmit", this.filesObj);
    },
  },
  created() {
    this.importType_ = this.importType;
  },
};
</script>
<style lang="scss" scoped>
.upload-box ::v-deep {
  display: flex;
  //   margin-right: 15px;
  .el-upload {
    width: 100%;
  }

  .el-button {
    width: 100%;
    border: 1px solid #dddddd;
    background: linear-gradient(0deg, #f4f4f4 0%, #ffffff 100%);
    border-radius: 6px;
    color: #666666;
    text-align: left;
    height: 40px;
  }

  .el-button.submit-btn {
    width: 100%;
    height: 40px;
    background: #28c76f !important;
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(40,199,111,0.28);
    border-radius: 6px;
    font-size: 16px;
    color: #fff;

    > span {
      display: flex;
      justify-content: space-around;
      align-items: center;
      svg {
        // position: absolute;
        //  left: 23px;
        font-size: 20px;
        color: #fff;
        margin-top: -5px;
      }
    }
  }
}
</style>